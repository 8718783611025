import React from "react";
// import { Link } from "gatsby"

import { Box, Container, Heading, Stack } from "@chakra-ui/react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Map from "../components/mapComponent";

const MapPage = () => (
  <Layout>
    <SEO title="The map with the bearbat sightings" />
    <Container p={2} bg="gray.100" borderRadius="xl" centerContent maxW="2xl">
      <Heading>Recent Bearbat sightings.   </Heading>
    </Container>
    <Box p={2} bg="gray.800" borderRadius="xl" mx="auto" w="85%" h={666}>
      <Map scrolWheel={true} />
    </Box>
  </Layout>
);

export default MapPage;
